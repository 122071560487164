import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String,
    timeout: Number
  }

  connect() {
    console.log("broadcast player connected", this.urlValue)
    if (this.hasTimeoutValue) {
      console.log("Setting timeout", this.timeoutValue)
      this.timeout = setTimeout(function() {
        console.log("Timeout")
        this.next()
      }.bind(this), this.timeoutValue)
    }
  }

  next() {
    console.log("Next!")
    $.rails.ajax({
      url: this.urlValue,
      dataType: "script"
    })
  }

  error(event) {
    console.log("Error", event)
    this.next()
  }

  disconnect() {
    console.log("Disconnecting...")
    if (this.timeout) {
      console.log("Clearing timeout")
      clearTimeout(this.timeout)
    }
  }
}
